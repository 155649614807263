import React, { useEffect, useState } from "react";
import { useLanguage } from "../../../contexts/languageContext";
import styles from './langToggle.module.css'
import BFL from "../../ui/bigUpperLetter/bigUpperLetter";
import { useScreenSize } from "../../../contexts/screenSizeContext";


type Props = {
	displayedAt: 'Topbar' | 'MenuBottom',
	className?: string
}

const LanguageToggle = ({ displayedAt, className }: Props) => {
	const { setLanguage } = useLanguage();
	const { screenSize } = useScreenSize();
	const [isDisplayed, setIsDisplayed] = useState<Boolean>(false);
	
	useEffect(() => {
		const isTopbarDisplayed = displayedAt == 'Topbar' && screenSize == 'Desktop';
		const isMenuBottomDisplayed = displayedAt == 'MenuBottom' && screenSize == 'Mobile';

		setIsDisplayed(isTopbarDisplayed || isMenuBottomDisplayed);
	}, [screenSize]);




	return isDisplayed
	? (<div className={styles.langToggle + ' ' + className}>
		<div className={styles.eng} onClick={() => { setLanguage('eng') }}>
			<BFL>En</BFL>
		</div>
		<div className={styles.kor} onClick={() => { setLanguage('kor') }}>
			<BFL>Kr</BFL>
		</div>
	</div>) 
	: <></>
}

export default LanguageToggle