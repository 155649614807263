import React, { useEffect, useState } from "react";
import Loading from "./components/ui/loading/Loading";
import { LanguageProvider } from "./contexts/languageContext";

import RouterProvider from "./routes";
import { ScreenSizeProvider } from "./contexts/screenSizeContext";

const App = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const onLoaded = () => {
            setIsLoading(false);
        }

        if (document.readyState === 'complete') { 
            onLoaded();
        }

        window.addEventListener('load', onLoaded);

        return () => window.removeEventListener('load', onLoaded);
    }, []);

    return (
        <React.StrictMode>
            {isLoading && <Loading />}
            <ScreenSizeProvider>
                <LanguageProvider>
                    <RouterProvider />
                </LanguageProvider>
            </ScreenSizeProvider>
        </React.StrictMode>
    );
};

export default App;
