import React from "react";
import { Link } from "react-router-dom";
import BFL from "../bigUpperLetter/bigUpperLetter";
import WrappedImg from "../img/WrappedImg";

import styles from './brandList.module.css';

type Props = {
	brands: Array<any>,
	page?: 'Home' | 'Brand'
}

const BrandList = ({ brands, page }: Props) => {
	if (!page) page = 'Home';

	return <div className={styles.wrap}>
		<div className={styles.brandList}>
			{brands
				.filter((brand) => brand.display !== "none")
				.map((brand, index) => (
					<Link to={'/brands/' + brand.id} className={styles.item} key={index}>
						<WrappedImg imgId={page === 'Home' ? brand.img.homeThumb : brand.img.brandsThumb} className={styles.imgWrap} />
						<span className={styles.link}>
							<span><BFL>{brand.name}</BFL></span>
						</span>
						{
							(page == 'Brand') && 
							(<span className={styles.brandName}>
								<BFL>{brand.name}</BFL>
							</span>)
						}					
					</Link>))
			}
		</div>
	</div>
}

export default BrandList;