import React from "react"
import WrappedImg from "../../img/WrappedImg";
import styles from './postBlock.module.css';

export type PostBlockType = 'Heading' | 'Heading2' | 'Paragraph' | 'Img' | 'ImgCaption' | 'List' | 'Table' | 'Hr';

export type PostBlockProps = {
	blockType: PostBlockType,
	content: any,
	className?: string
}

export const PostBlock = (props: PostBlockProps) => {
	switch (props.blockType) {
		case 'Heading':
			return <HeadingBlock {...props}/>;
		case 'Heading2':
			return <Heading2Block {...props}/>;
		case 'Paragraph':
			return <ParagraphBlock {...props}/>;
		case 'Img':
			return <ImgBlock {...props}/>;
		case 'ImgCaption':
			return <ImgCaptionBlock {...props}/>;
		case 'List':
			return <ListBlock {...props}/>;
		case 'Table':
			return <TableBlock {...props}/>;
		case 'Hr':
			return <HrBlock {...props}/>;
		default:
			return <></>;
	}
}

const HeadingBlock = ({content, className}: PostBlockProps) => {
	return <h1 className={`${className} ${styles.Heading}`}>
		{content}
	</h1>
}

const Heading2Block = ({content, className}: PostBlockProps) => {
	return <h2 className={`${className} ${styles.Heading2}`}>
		{content}
	</h2>;
}

const ParagraphBlock = ({content, className}: PostBlockProps) => {
	return <p className={`${className} ${styles.Paragraph}`}>
		{content}
	</p>
}

const ImgBlock = ({content, className}: PostBlockProps) => {
	return <WrappedImg imgId={content} className={`${className} ${styles.Img}`}/>
}

const ImgCaptionBlock = ({content, className}: PostBlockProps) => {
	return <p className={`${className} ${styles.ImgCaption}`}>
		{content}
	</p>
}

const ListBlock = ({content, className}: PostBlockProps) => {
	return <ul className={className}> 
		{
			content.map((el: string) => <li>{el}</li>)
		}
	</ul>;
}

const TableBlock = ({content, className}: PostBlockProps) => {
	return <table className={`${className} ${styles.Table}`}>
		<tbody>
		{(content as Array<Array<string>>).map((row, index) => 
			<tr key={index}>
				{row.map((cell, index) => <td key={index}>{cell}</td>)}
			</tr>
		)}
		</tbody>
	</table>;
}

const HrBlock = ({content, className}: PostBlockProps) => {
	return <></>;
}