import React, { createContext, useContext, useEffect, useState } from "react";

type SupportedLanguage = "kor" | "eng";
const LanguageContext = createContext<any>(null);

export function useLanguage(): {
    language: SupportedLanguage,
    setLanguage: React.Dispatch<React.SetStateAction<SupportedLanguage>>,
} {
    return useContext(LanguageContext);
}

type Props = {
    children: React.ReactNode;
};

export const LanguageProvider = ({ children }: Props) => {
    const [language, setLanguage] = useState<SupportedLanguage| undefined>();

    // save language to local storage.
    useEffect(() => {
        // Initalize language
        if (!language) {
            const storedLanguage = window.localStorage.getItem('lang') as SupportedLanguage;
            if (storedLanguage) {
                setLanguage(storedLanguage);
            } else {
                setLanguage('eng');
                window.localStorage.setItem('lang', 'eng');
            }
        } else {
            window.localStorage.setItem('lang', language);
        }
    }, [language]);


    return <LanguageContext.Provider value={{language, setLanguage}}>
        { children }
    </LanguageContext.Provider>;
};


