import React from "react";

import Img from "./Img";

type WrappedImgProps = {
	imgId: string,
	alt?: string,
	style?: any,
	className?: any
}

const WrappedImg = (props: WrappedImgProps) => {
	const {imgId, alt, style, className} = props;
	return <div style={style} className={className}>
		<Img imgId={imgId} alt={alt} style={{width: '100%'}}/>
	</div>
}

export default WrappedImg;