import React, { useState } from "react";
import styles from './DropDown.module.css';

type Props = {
	isOpen: any
	children: any
	className: string
}

const DropDown = (props: Props) => {
	const {isOpen, children, className} = props;

	return <div className={`${isOpen ? styles.wrap + ' ' + styles.open : styles.wrap} ${className}`}>
		{children}
	</div>
}

export default DropDown;