import React from "react"

import styles from './loading.module.css';


const Loading = () => {
	return (
		<div className={styles.Loading}>
      <img
        src="https://pz-acorn-studio-homepage.b-cdn.net/imgs/eng/blue_acorn.png"
        className={styles.acorn}
		/>
      <img
        src="https://pz-acorn-studio-homepage.b-cdn.net/imgs/eng/red_acorn.png"
        className={styles.acorn}
		/>
      <img
        src="https://pz-acorn-studio-homepage.b-cdn.net/imgs/eng/green_acorn.png"
        className={styles.acorn}
		/>
    </div>
  );
};

export default Loading;