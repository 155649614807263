import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import BUL from "../../components/ui/bigUpperLetter/bigUpperLetter";
import WrappedImg from "../../components/ui/img/WrappedImg";
import Loading from "../../components/ui/loading/Loading";
import MyPre from "../../components/ui/myPre/MyPre";
import Title from "../../components/ui/title/Title";
import { useData } from "../../hooks/useData";

import styles from "./about.module.css";
import Desc from "./components/Desc";
import { useScreenSize } from "../../contexts/screenSizeContext";

const About = () => {
	const aboutData = useData('about');
	
	const {screenSize} = useScreenSize()
	
	const [isLearnMoreOpen, setIsLearnMoreOpen] = useState<boolean>(false);
	const [selectedPersonIdx, setSelecedPersonIdx] = useState<number>(0);
	
	const amIOpen = (index: number) => selectedPersonIdx === index && isLearnMoreOpen;

	if (!aboutData) return <Loading />

	return (<div className={styles.about}>
		<div className={styles.logo}>
			<WrappedImg className={styles.logoImgWrap} imgId={aboutData.logo}/>
		</div>
		<div className={styles.moto}>
			{aboutData.moto}
		</div>
		<div className={styles.desc}>
			{
				screenSize == 'Mobile' ? 
				(aboutData.desc) :
				(<MyPre>{aboutData.desc}</MyPre>)
			}
		</div>
		<Title title={'MANAGEMENT'}/>
		<div className={styles.people}>
			{aboutData.people.map((person: any, index: number) => (
				<>
					<div className={`${styles.person} ${amIOpen(index)? styles.selected : ''}`} key={index}>
						<WrappedImg className={styles.profileImgWrap} imgId={person.imgId}/>
						<div className={styles.name}><BUL>{person.name}</BUL></div>
						<div className={styles.role}>{person.role}</div>
						<div className={styles.learnMoreBtn} onClick={() => {
							if (amIOpen(index)) {
								setIsLearnMoreOpen(false);
							} else {
								setIsLearnMoreOpen(true);
								setSelecedPersonIdx(index)
							}
						}}>
							{
								amIOpen(index)
								? <>{'Close'}<img src='/icons/deepBlueArrow.png' /></>
								: <>{'Learn More'}<img src='/icons/blueArrow.png' /></>
							}
						</div>
					</div>
					<div className={`${styles.learnMore} ${(amIOpen(index) ? styles.open : styles.close)}`}>
						<Desc blocks={person.desc} />
					</div>
				</>
			))}
		</div>
	</div>);
}

export default About;