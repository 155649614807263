import React from "react"

type Props = {
	children: string | undefined
}

const BUL = ({ children }: Props) => {
	if (!children) return <></>;
	const findUpperAndSpace = /[A-Z|\s.]+/g;
	const thisIsBigMark = '!!';
	const splitHere = '}?'
	const replaceValue = `${splitHere}${thisIsBigMark}$&${splitHere}`

	return <>{
			children
			.replaceAll(findUpperAndSpace, replaceValue)
			.split('}?')
			.map((text, index) => {
				let fontSize = '0.9em';
				if (text.includes('!!')) {
					fontSize = '1.1em';
				}
				return <span key={index} style={{fontSize}}>{text.replace('!!', '')}</span>;
			})
	}</>
}

export default BUL;