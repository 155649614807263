import React from "react";

import { useState } from "react";
import Footer from "./footer/Footer";
import Menu from "./menu/Menu";
import Topbar from "./topbar/Topbar";
import TopBtn from "./topBtn/TopBtn";

const Layout = ({ children }: {children: any}) => {
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

	return <>
		<Topbar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>
		<Menu isMenuOpen={isMenuOpen} onMenuItemClick={() => {setIsMenuOpen(!isMenuOpen);}}/>
		{ children }
		<Footer />
		<TopBtn />
	</>;
}

export default Layout;