import React, { useState } from "react";

import { Link } from 'react-router-dom';
import { useData } from '../../../hooks/useData';
import BFL from "../../ui/bigUpperLetter/bigUpperLetter";
import WrappedImg from '../../ui/img/WrappedImg';
import styles from './menu.module.css';
import LanguageToggle from "../LanguageToggle/LanguageToggle";
import { useScreenSize } from "../../../contexts/screenSizeContext";
import Icon from "../../ui/icon/Icon";
import BUL from "../../ui/bigUpperLetter/bigUpperLetter";
import Loading from "../../ui/loading/Loading";
import DropDown from "../../ui/dropDown/DropDown";

type MenuItemProps = {
	text: string,
	to: string,
	acornColor: string,
	onClick: any
}
const MenuItem = (props: MenuItemProps) => {
	const ImgId = useData('imgs', 'acorn', props.acornColor);

	return props.text === 'Brands'
	? <BrandMenuItem {...props} /> 
	: (<div className={styles.menuItem}>
		<WrappedImg imgId={ImgId} className={styles.menuItemImg}/>
		<Link to={props.to} className={styles.menuItemName} onClick={props.onClick}>
			<BFL>{props.text}</BFL>
		</Link>
	</div>);
}

const BrandMenuItem = (props: MenuItemProps) => {
	const ImgId = useData('imgs', 'acorn', props.acornColor);
	const brandsData = useData('brands');
	const {screenSize} = useScreenSize();
	const [isDropdownOpen, setIsDropdownOpen] = useState<Boolean>(false);

	const toggleDropDown = () => {
		setIsDropdownOpen(!isDropdownOpen);
	}

	if (!brandsData) return <Loading />;

	return screenSize == 'Desktop'
	? (<Link to={props.to} className={styles.menuItem} onClick={props.onClick}>
		<WrappedImg imgId={ImgId} className={styles.menuItemImg}/>
		<span className={styles.menuItemName}><BFL>{props.text}</BFL></span>
	</Link>)
	: (<div className={styles.menuItem}>
		<WrappedImg imgId={ImgId} className={styles.menuItemImg}/>
		<Link to={props.to} className={styles.menuItemName} onClick={props.onClick}>
			<BFL>{props.text}</BFL>
		</Link>
		<span className={isDropdownOpen ? `${styles.dropDownBtn} ${styles.open}` : styles.dropDownBtn} onClick={toggleDropDown}>
			<Icon id={'downArrow_black'}/>
		</span>

		<DropDown className={isDropdownOpen ? `${styles.brandDetailDropdown} ${styles.open}` : styles.brandDetailDropdown} isOpen={isDropdownOpen}>
			{
				Object.entries(brandsData).map(([id, brand]: [string, any]) => (
					<Link 
						to={`/brands/${id}`} 
						onClick={() => {
							props.onClick();
							setIsDropdownOpen(false);
						}}
					>
						<BUL>{brand.name}</BUL>
					</Link>
				))
			}
		</DropDown>
	</div>)
}

type MenuProps = {
	isMenuOpen: boolean,
	onMenuItemClick: Function
}
const Menu = (props: MenuProps) => {
	const { onMenuItemClick } = props;
	const menuData = useData('menu') as Array<{name: string, path: string, color: string}>;

	let menuItems;
	if (menuData) {
		menuItems = menuData.map((data, index) => {
			return <MenuItem text={data.name} to={data.path} acornColor={data.color} onClick={onMenuItemClick} key={index}/>
		});
	}

	return <div className={`${styles.menu} ${props.isMenuOpen ? styles.menuOpen : ''}`}>
			<div className={styles.menuWrap}>
				{menuItems}
			</div>
			<LanguageToggle displayedAt="MenuBottom" className={styles.langToggle}/>
		</div>;
}

export default Menu;