import React, { useEffect, useRef } from "react";

export const useInterval = (callback: Function, delay: number | null) => {
    const savedCallback = useRef<Function | undefined>();
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            if (savedCallback.current) {
                savedCallback.current();
            }
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
};
