import React from "react";

import { useData } from "../../hooks/useData";
import Loading from "../../components/ui/loading/Loading";
import Map from "./Map/Map";

import styles from "./contact.module.css";
import Title from "../../components/ui/title/Title";
import BUL from "../../components/ui/bigUpperLetter/bigUpperLetter";

const Contact = () => {

	const contactData = useData('contact');

	if (!contactData) return <Loading />;

	return <div>
		<Title title='CONTACT' className={styles.pageTitle}/>
		<div className={styles.contantWrap}>
			<div className={styles.emailWrap}>
				<div className={styles.emailTitle}>
					<img src="./icons/mail.png" />
					<BUL>E-mails</BUL>
				</div>
				{
					contactData.emails.map((email: any, index: number) =>(
						<div className={styles.email}>
							<div className={styles.propose}>
								<BUL>{email.propose}</BUL>
							</div>
							<div className={styles.emailAdress}>
								{email.email}
							</div>
						</div>
					))
				}
			</div>
			<Map apiKey={contactData.apiKey} officeLocation={contactData.location} />
		</div>
	</div>
}

export default Contact;