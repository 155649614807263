import React, { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import { useData } from "../../../hooks/useData";
import { useScreenSize } from "../../../contexts/screenSizeContext";

import WrappedImg from "../../ui/img/WrappedImg";
import styles from './topbar.module.css'
import LanguageToggle from "../LanguageToggle/LanguageToggle";

type TopbarProps = {
	isMenuOpen: boolean,
	setIsMenuOpen: Function
}

const topbarTextColor = [
	{ reg: /^\/$/, color: "white" },
	{ reg: /^\/home$/, color: "white" },
	{ reg: /^\/about$/, color: "black" },
	{ reg: /^\/business$/, color: "black" },
	{ reg: /^\/brands\/mono/, color: "white" },
	{ reg: /^\/brands\/folding/, color: "white" },
	{ reg: /^\/brands/, color: "black" },
];

const Topbar = (props: TopbarProps) => {
	const {isMenuOpen, setIsMenuOpen} = props;
	const location = useLocation();

	if (!location) return <></>;

	const getTextColor = (): string => {
		const DEFAULT_COLOR = 'gray'

		if (isMenuOpen) return DEFAULT_COLOR;
		
		let textColor = topbarTextColor.find((val) => location.pathname.match(val.reg));
		if (textColor) return textColor.color;

		return DEFAULT_COLOR;
	}

	return <nav className={`${styles.topbar} ${styles[getTextColor()]} ${isMenuOpen ? styles.fixed : ''}`}>
		<Logo />
		<LanguageToggle displayedAt="Topbar"/>
		<Hamburger isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>
	</nav>;
}

const Logo = () => {
	const {screenSize} = useScreenSize();
	const desktopLogo = useData('imgs', 'acorn', 'blue');
	const mobileLogo = useData('imgs', 'logoMobile');

	const [imgId, setImgId] = useState(desktopLogo);

	useEffect(() => {
		if (screenSize == 'Desktop') {
			setImgId(desktopLogo);
		} else if (screenSize == 'Mobile') {
			setImgId(mobileLogo);
		}
	}, [screenSize, desktopLogo, mobileLogo]);

	return <Link to='/' className={`${styles.logoWrap}`}>
		<WrappedImg imgId={imgId}/>
	</Link>
}

type HamburgerProps = {
	isMenuOpen: boolean,
	setIsMenuOpen: Function
}

const Hamburger = (props: HamburgerProps) => {
	const {isMenuOpen, setIsMenuOpen} = props;

	const btnClassNames = `
		${styles.hamburgerBtn}
		${isMenuOpen ? styles.menuOpen : ''}
	`;

	const toggleIsMenuOpen = () => {
		setIsMenuOpen(!isMenuOpen);
	}

	return <div className={styles.hamburger}>
		<div className={btnClassNames} onClick={toggleIsMenuOpen}></div>
	</div>
}

export default Topbar;