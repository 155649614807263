import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Error from "../../components/ui/error/Error";
import WrappedImg from "../../components/ui/img/WrappedImg";
import Loading from "../../components/ui/loading/Loading";
import MyPre from "../../components/ui/myPre/MyPre";
import { useData } from "../../hooks/useData";

import styles from "./careerPost.module.css";
import { useScreenSize } from "../../contexts/screenSizeContext";
import { Post } from "../../components/ui/post/Post";

const CareerPost = () => {
	const { postId } = useParams();
	const posts = useData('careers', 'posts');

	const {screenSize} = useScreenSize()

	if (!postId || !posts || !screenSize) return <Loading />

	const post = posts.find((post: any) => {return post.id === postId});

	if (!post) return <Error />;

	return (<div className={styles.careerPost}>
		<div className={styles.title}>{post.title}</div>
		<div className={styles.subtitle}>{[post.department, post.employType, post.experience].join(' / ')}</div>
		<WrappedImg imgId="/eng/careerPostBanner.png" />
		<Post blocks={post.content} classNames={[
			{blockType: 'Heading', className: styles.sectionTitle},
			{blockType: 'Heading2', className: styles.sectionSubTitle},
			{blockType: 'Paragraph', className: styles.paragraph},
			{blockType: 'Table', className: styles.table}
		]} />
	</div>);
}

export default CareerPost