import React from "react";

type ImgProps = {
	imgId: string,
	alt?: string,
	style?: React.CSSProperties,
	className?: string
}

const imgServerURL = 'https://pz-acorn-studio-homepage.b-cdn.net/imgs';

const Img = (props: ImgProps) => {
	const imgURL = imgServerURL + props.imgId;
	return <img 
		src={imgURL} 
		alt={props.alt ? props.alt : 'Failed to load image.'} 
		style={props.style} 
		className={props.className}
	/>;
}



export default Img;