import React, { useState } from "react";
import Loading from "../../components/ui/loading/Loading";
import NewsThumbnail from "../../components/ui/newsThumbnail/NewsThumbnail";

import Title from "../../components/ui/title/Title";
import { useData } from "../../hooks/useData";

import styles from './news.module.css';
import BUL from "../../components/ui/bigUpperLetter/bigUpperLetter";



const News = () => {
	const newsData = useData('news') as Array<any>;

	const [page, setPage] = useState<number>(1);
	const NEWS_PER_PAGE = 9;

	if (!newsData) return <Loading />;

	newsData.sort((a, b) => {
		const aDate = new Date(a.date);
		const bDate = new Date(b.date);
		return bDate.getTime() - aDate.getTime();
	})
	
	const isLastPage = newsData.length <= page * NEWS_PER_PAGE;

	const handleLoadMore = () => {
		setPage(page + 1);
	}

	return <div className={styles.news}>
			<>
	<Title title='NEWS' className={styles.title}/>
		<div className={styles.newsWrap}>
			{
				newsData
				.slice(0, page * NEWS_PER_PAGE)
				.map((news, index) => (
					<NewsThumbnail 
						title={news.title}
						imgId={news.thumbnailImgId}
						date={news.date}
						to={`/news/${news.id}`}
						styleType='newsPage'
						key={index}
					/>
				))
			}
		</div>
		{
			!isLastPage &&
			<div className={styles.loadMoreBtn} onClick={handleLoadMore}>
				<BUL>More</BUL>
			</div>
		}
	</>
	</div>
}

export default News;