import React from "react";

const MyPre = ({children}: {children: string}) => {
	if (!children) return <></>;

	return <>{
		children
			.split(/\n|<br>/)
			.map((string, index) => [string, <br key={index}/>])
			.flat()
	}</>
}

export default MyPre;