import React from "react";
import { Link } from "react-router-dom";
import Loading from "../../components/ui/loading/Loading";
import Title from "../../components/ui/title/Title";
import { useLanguage } from "../../contexts/languageContext";
import { useData } from "../../hooks/useData";

import styles from "./careers.module.css";

const Careers = () => {
	const careersData = useData('careers');
	const {language} = useLanguage();

	if (!careersData) return <Loading />;

	let postNumberMsg;
	if (language === 'eng') {
		postNumberMsg = <><span className={styles.blue}>{careersData.posts.length}</span> Open Jobs.</>;
	} else {
		postNumberMsg = <>총 <span className={styles.blue}>{careersData.posts.length}</span>건의 채용이 진행 중 입니다.</>;
	}

	return <div className={styles.careers}>
		<Title title="Careers" className={styles.pageTitle}/>
		<div className={styles.postNumber}>{postNumberMsg}</div>
		{
			careersData.posts.map((post: any, index: number) => (
				<Link className={styles.post} to={`/careers/post/${post.id}`} key={index}>
					<div className={styles.upperSubtitle}>{post.employType + ' · ' + post.experience}</div>
					<div className={styles.postTitle}>{post.title}</div>
					<div className={styles.underSubtitle}>{[post.department, post.employType, post.experience].join(' / ')}</div>
				</Link>
			))
		}
	</div>
}

export default Careers;