import React from 'react'

import WrappedImg from '../../components/ui/img/WrappedImg';
import { useData } from '../../hooks/useData';
import Loading from '../../components/ui/loading/Loading';

import styles from './business.module.css';
import BUL from '../../components/ui/bigUpperLetter/bigUpperLetter';

const Business = () => {
	const businessData = useData('business');

	if (!businessData) return <Loading />

	return (
		<div className={styles.business}>
			<div className={styles.banner} >
				<WrappedImg className={styles.imgWrap} imgId={businessData.bannerImg} />
				<div className={styles.pageName}><BUL>OUR BUSINESS</BUL></div>
			</div>
			<div className={styles.contentsWrap}>
				{
					businessData.contents.map((content:any) => (
						<div className={styles.content}>
							<WrappedImg className={styles.imgWrap} imgId={content.img} />
							<div className={styles.texts}>
								<div className={styles.title}><BUL>{content.title}</BUL></div>
								<WrappedImg className={styles.imgWrap} imgId={content.img} />
								<div className={styles.oneLine}>{content.oneLine}</div>
								<div className={styles.desc}>{content.desc}</div>
							</div>
						</div>
					))
				}
			</div>
		</div>
	)
}

export default Business;