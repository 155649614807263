import React, { useEffect, useState } from "react";

import styles from "./carousel.module.css"
import { useData } from "../../../../hooks/useData";
import { Link } from "react-router-dom";
import WrappedImg from "../../../../components/ui/img/WrappedImg";
import Loading from "../../../../components/ui/loading/Loading";
import { useInterval } from "../../../../hooks/useInterval";
import Icon from "../../../../components/ui/icon/Icon";
import { useSwipeable } from "react-swipeable";

type SildePosition = 'prev' | 'curr' | 'next' | 'hide';

type CarouselData = {
	img: string,
	to: string
}

const Carousel = () => {
	const carouselData = useData('carousel') as Array<CarouselData>;
	
	const [currSlideIdx, setCurrSlideIdx] = useState<number>(0);
	const [desiredSlideIdx, setDesiredSlideIdx] = useState<number>(0);
	const [indicatorClicked, setIndicatorClicked] = useState<boolean>(false);
	const [sildePositions, setSlidePositions] = useState<Array<SildePosition>>([]);
	
	const [intervalActivated, setIntervalActivated] = useState<Boolean>(true);
	const DEFAULT_DELAY = 5000;

	let len = carouselData ? carouselData.length : 1;

	const getPrevIdx = () => (currSlideIdx - 1 + len) % len;
	const getNextIdx = () => (currSlideIdx + 1 + len) % len;

	const setCurrIdxPrev = () => setCurrSlideIdx(getPrevIdx());
	const setCurrIdxNext = () => setCurrSlideIdx(getNextIdx());

	const isCurr = (index: number) => (index == currSlideIdx);
	const isPrev = (index: number) => (index == getPrevIdx());
	const isNext = (index: number) => (index == getNextIdx());

	const acitvateAutoSlide = () => setTimeout(() => setIntervalActivated(true), 3000);
	const deactivateAutoSlide = () => setIntervalActivated(false);

	const swipeHanlder = useSwipeable({
		onSwipeStart: (event) => {
			deactivateAutoSlide();
		},
		onSwiped: (event) => {
			acitvateAutoSlide();
		},
		onSwipedRight: (event) => {
			setCurrIdxPrev();
		},
		onSwipedLeft: (event) => {
			setCurrIdxNext();
		},
	});

	useInterval(setCurrIdxNext, intervalActivated ? DEFAULT_DELAY : null);

	useEffect(() => {
		if (!carouselData) return;
		setSlidePositions(
			new Array(carouselData.length)
			.fill('hide')
			.map((pos, idx) => {
				if (isCurr(idx)) return 'curr';
				else if (isNext(idx)) return 'next';
				else if (isPrev(idx)) return 'prev';
				return 'hide';
			})
		);
	}, [currSlideIdx, carouselData]);

	useEffect(() => {
		if (indicatorClicked) {
			setTimeout(() => {
				if (desiredSlideIdx > currSlideIdx) setCurrIdxNext();
				else if (desiredSlideIdx < currSlideIdx) setCurrIdxPrev();
				else setIndicatorClicked(false);
			}, 100);
		}
	}, [desiredSlideIdx, currSlideIdx, indicatorClicked])

	if (!carouselData) return <Loading />;

	return (
	<div 
		className={styles.wrap} 
		onMouseEnter={deactivateAutoSlide} 
		onMouseLeave={acitvateAutoSlide}
		{...swipeHanlder} 
		>
		<MoveBtn className={styles.nextBtn} onClick={setCurrIdxNext}>
			<Icon id='rightArrow' className={styles.btnIcon}/>
		</MoveBtn>

		<MoveBtn className={styles.prevBtn} onClick={setCurrIdxPrev}>
			<Icon id='leftArrow' className={styles.btnIcon}/>
		</MoveBtn>

		<PageNumber current={currSlideIdx + 1} total={len}/>

		<div className={styles.indicatorWrap}>
			{
				sildePositions.map((position, idx) => {
					return <Indicator 
						key={idx}
						selected={(position == 'curr')}
						onClcik={() => {
							setIndicatorClicked(true);
							setDesiredSlideIdx(idx);
						}}
					/>
				})
			}
		</div>

		{
			carouselData.map((slide, idx) => (
				<Slide 
					imgId={slide.img}
					to={slide.to}
					position={sildePositions[idx]}
					key={idx}
				/>
			))
		}
	</div>);
}

type SildeProps = {
	imgId: string,
	to: string,
	position: SildePosition
}

const Slide = (props: SildeProps) => {
	return <Link to={props.to} className={`${styles.slide} ${styles[props.position]}`}>
		<WrappedImg imgId={props.imgId} className={styles.imgWrap}/>
	</Link>
}

const MoveBtn = (props: {children?:any, onClick?: any, className?: string}) => {
	return <div className={`${styles.btn} ${props.className}`} onClick={props.onClick}>
		{props.children}
	</div>
}

const Indicator = (props: {onClcik: any, selected: boolean}) => {
	return <div 
		className={`${styles.indicator} ${props.selected ? styles.selected : ''}`}
		onClick={props.onClcik}
	/>
}

const PageNumber = (props: {current: number, total: number}) => {
	return <div className={styles.pageNumber}>
		{`${props.current} / ${props.total}`}
	</div>
}

export default Carousel