import React from "react";

import styles from "./newsThumbnail.module.css"
import WrappedImg from "../img/WrappedImg";
import { Link } from "react-router-dom";
import { useLanguage } from "../../../contexts/languageContext";
import MyPre from "../myPre/MyPre";

type Props = {
	imgId: string,
	title: string,
	date: string,
	to: string,
	styleType?: 'home' | 'brandRelated' | 'newsPage'
}

const NewsThumbnail = (props: Props) => {
	const { language } = useLanguage();

	const date = new Date(props.date);
	let formattedDate;
	if (language === 'eng') {
		const monthToEng = (month: number) => ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][month].toUpperCase();
		formattedDate = `${monthToEng(date.getMonth())} ${date.getDate()}, ${date.getFullYear()}`;
	} else {
		formattedDate = `${date.getFullYear()}년 ${date.getMonth()}월 ${date.getDate()}일`;
	}
	
	const styleType = props.styleType ? props.styleType : 'home';

	return <Link className={styles.wrap + ' ' + styles[styleType]} to={props.to}>
			<WrappedImg className={styles.imgWrap} imgId={props.imgId} />
		<div className={styles.title}>{props.title}</div>
		<div className={styles.date}>{formattedDate}</div>
	</Link>
}

export default NewsThumbnail;