import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Loading from "../../components/ui/loading/Loading";
import Error from "../../components/ui/error/Error";

import { useData } from "../../hooks/useData";

import styles from './newsDetail.module.css';
import { useLanguage } from "../../contexts/languageContext";
import WrappedImg from "../../components/ui/img/WrappedImg";
import MyPre from "../../components/ui/myPre/MyPre";
import { useScreenSize } from "../../contexts/screenSizeContext";
import { Post } from "../../components/ui/post/Post";
import { PostBlockProps } from "../../components/ui/post/postBlock/PostBlock";

const NewsDetail = () => {
	const { language } = useLanguage();
	const { screenSize } = useScreenSize();

	const newsData = useData('news') as Array<any>;
	const { newsId } = useParams();

	if (!newsData) return <Loading />;

	let newsDetailData;
	if (newsId) {
		newsDetailData = newsData.find((news: any) => news.id === newsId);
		if (!newsDetailData) return <Error />;

		const date = new Date(newsDetailData.date);
		if (language === 'eng') {
			const monthToEng = (month: number) => ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][month].toUpperCase();
			newsDetailData.formattedDate = `${monthToEng(date.getMonth())} ${date.getDate()}, ${date.getFullYear()}`;
		} else {
			newsDetailData.formattedDate = `${date.getFullYear()}년 ${date.getMonth()}월 ${date.getDate()}일`;
		}
	}

	return (
		<div className={styles.newsDetailWrap}>
			<Link to='/news' className={styles.newsDetailPageName}>NEWS</Link>
			<div className={styles.newsTitle}>{
				screenSize == 'Desktop'
				? <MyPre>{newsDetailData.title}</MyPre>
				: newsDetailData.title
			}</div>
			<div className={styles.newsDate}>{newsDetailData.formattedDate}</div>
			<Post blocks={newsDetailData.post.map((block: PostBlockProps) => {
				if (block.blockType == 'Img'){
					block.className = styles.newsImg;
				}
				return block;
			})}/>
			{/* <WrappedImg className={styles.newsImg} imgId={newsDetailData.imgId}/>
			<div className={styles.newsImgCaption}><MyPre>{newsDetailData.imgCation}</MyPre></div>
			<div className={styles.newsContent}><MyPre>{newsDetailData.content}</MyPre></div> */}
		</div>
	);
}

export default NewsDetail