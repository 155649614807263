import { useEffect, useState } from "react";
import { useLanguage } from "../contexts/languageContext";

const isTest = window.location.hostname.includes("test");
const DATA_URL =
    (process.env.REACT_APP_DATA_URL as string) + (isTest ? "/test" : "");

export type ReadDataFunction = (args: Array<string>) => Promise<any>;

let eng = new Promise<any>((resolve, reject) =>
    fetch(DATA_URL + "/eng.json", { cache: "no-store" })
        .then((result) => result.json())
        .then((json) => resolve(json))
);

export const English: ReadDataFunction = async (args: Array<string>) => {
    return args.reduce((prev, cur: string) => {
        return prev[cur];
    }, Object(await eng));
};

let kor = new Promise<any>((resolve, reject) =>
    fetch(DATA_URL + "/kor.json", { cache: "no-store" })
        .then((result) => result.json())
        .then((json) => resolve(json))
);
export const Korean: ReadDataFunction = async (args: Array<string>) => {
    return args.reduce((prev, cur: string) => {
        return prev[cur];
    }, Object(await kor));
};

export const useData = (...args: Array<string>): any => {
    const { language } = useLanguage();
    const [data, setData] = useState<any>();

    const filterDisplayNone = (data: any) => {
        if (data === undefined) return undefined;

        try {
            if (args.includes("brands")) {
                return data.filter((brand: any) => brand.display !== "none");
            }

            if (args.includes("news")) {
                return data.filter((news: any) => news.display !== "none");
            }

            if (args.includes("careers")) {
                data.posts = data.posts.filter(
                    (career: any) => career.display !== "none"
                );
                return data;
            }
        } catch (error) {
            console.error(error);
            console.log(args);
            console.log(data);
        }

        return data;
    };

    useEffect(() => {
        if (!language) return;
        if (language === "eng") {
            English(args).then((newData) =>
                setData(filterDisplayNone(newData))
            );
        } else {
            Korean(args).then((newData) => setData(filterDisplayNone(newData)));
        }
    }, [language]);

    return data;
};
