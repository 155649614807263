import React from "react";
import { BrowserRouter, Route, Routes} from "react-router-dom";

import Home from "../pages/home/Home";
import Layout from "../components/layouts/Layout";
import About from "../pages/about/About";
import Business from "../pages/business/Business";
import Brands from "../pages/brands/Brands";
import BrandsDetail from "../pages/brandsDetail/BrandsDetail";
import News from "../pages/news/News";
import NewsDetail from "../pages/newsDetail/newsDetail";

import Error from "../components/ui/error/Error"
import Careers from "../pages/careers/Careers";
import Contact from "../pages/contact/Contact";
import CareerPost from "../pages/careerPost/CareerPost";
import ScrollToTop from "../components/util/ScrollToTop";

const RouterProvider = () => {
	return <BrowserRouter>
		<Layout>
			<Routes>
				<Route path="/" element={<Home />}/>
				<Route path="/home" element={<Home />}/>
				<Route path="/about" element={<About />}/>
				<Route path="/business" element={<Business />}/>
				<Route path="/brands" element={<Brands />}/>
				<Route path="/brands/:brandId" element={<BrandsDetail />}/>
				<Route path="/news" element={<News />}/>
				<Route path="/news/:newsId" element={<NewsDetail />}/>
				<Route path="/careers" element={<Careers />} />
				<Route path="/careers/post/:postId" element={<CareerPost />} />
				<Route path="/contact" element={<Contact />} />
				<Route path="*" element={<Error />} />
			</Routes>
		</Layout>
		<ScrollToTop />
	</BrowserRouter>
}

export default RouterProvider;