import React, { createContext, useContext, useEffect, useState } from "react";

type ScreenSize = "Desktop" | "Mobile";
const ScreenSizeContext = createContext<any>(null);

export function useScreenSize(): {
    screenSize: ScreenSize,
    setScreensize: React.Dispatch<React.SetStateAction<ScreenSize>>,
} {
    return useContext(ScreenSizeContext);
}

type Props = {
    children: React.ReactNode;
};

let timer: any;
const debounce = (fn: Function) => {
    const debounceDelay = 50;

    clearTimeout(timer);
    timer = setTimeout(() => {
        timer = null;
        fn();
    }, debounceDelay);
};

const defaultSize: ScreenSize = "Desktop";
const mobileMaxScreenSize = 440;

const isMobile = (windowWidth: number) => windowWidth <= mobileMaxScreenSize;
const isDesktop = (windowWidth: number) => windowWidth > mobileMaxScreenSize;
let curWindowWidth = 0;

export const ScreenSizeProvider = ({ children }: Props) => {
    const [screenSize, setScreenSize] = useState<ScreenSize>(defaultSize);
	const [innerWidth, setInnerWidth] = useState(0);

    const updateInnerWidth = () => {
        setInnerWidth(window.innerWidth);
    };

    const updateFontSize = () => {
        const HTML = document.querySelector("html");
        if (!HTML) return;
        if (window.outerWidth == curWindowWidth) return;
        curWindowWidth = window.outerWidth;
        HTML.style.fontSize = `${(window.innerWidth / 2560) * 16}px`;
    };

    const onResizeHandler = () => {
        debounce(() => {
            updateFontSize();
            updateInnerWidth();
        });
    }

    window.addEventListener("load", onResizeHandler);
    window.addEventListener("resize", onResizeHandler);

    useEffect(() => {
        if (isMobile(innerWidth)) {
            setScreenSize("Mobile");
        } else if (isDesktop(innerWidth)) {
            setScreenSize("Desktop");
        }
    }, [innerWidth]);


    return <ScreenSizeContext.Provider value={{screenSize, innerWidth}}>
        { children }
    </ScreenSizeContext.Provider>;
};


