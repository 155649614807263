import React from "react";

import BrandList from "../../components/ui/brandList/BrandList";
import WrappedImg from "../../components/ui/img/WrappedImg";
import Loading from "../../components/ui/loading/Loading";
import Title from "../../components/ui/title/Title";

import { useData } from "../../hooks/useData";
import { useParams } from "react-router-dom";

import styles from "./brands.module.css";
import MyPre from "../../components/ui/myPre/MyPre";
import { useScreenSize } from "../../contexts/screenSizeContext";


const Brands = () => {
	const brandPageData = useData('brandPage');
	const brandsData = useData('brands') as Array<any>;

	const {screenSize} = useScreenSize();

	const isDataLoding = !brandPageData || !brandsData

	if (isDataLoding) return <Loading />;

	const {defaultBannerImgId, desc, desc_mobile } = brandPageData;

	return (<div className={styles.brands}>
		<div className={styles.banner}>
			<WrappedImg 
				imgId={defaultBannerImgId}
				className={styles.imgWrap}
			/>
		</div>

		<Title title='BRANDS' />
		<p className={styles.desc}>
			<MyPre>{screenSize == 'Desktop' ? desc : desc_mobile}</MyPre>
		</p>
		<BrandList brands={brandsData} page='Brand'/>
	</div>);
}

export default Brands;