import React from "react";

import Carousel from "./components/carousel/Carousel";
import Title from "../../components/ui/title/Title"

import { useData } from "../../hooks/useData";

import styles from "./home.module.css";
import WrappedImg from "../../components/ui/img/WrappedImg";
import BrandList from "../../components/ui/brandList/BrandList";
import NewsThumbnail from "../../components/ui/newsThumbnail/NewsThumbnail";
import Loading from "../../components/ui/loading/Loading";
import BUL from "../../components/ui/bigUpperLetter/bigUpperLetter";

const Home = () => {
	const introData = useData('intro');
	const businessImg = useData('imgs', 'business');
	const brandsData = useData('brands');
	const newsData = useData('news') as Array<any>;
	const mainNewsIds = useData('mainNewsIds') as Array<any>;

	const isDataLoading = !introData || !businessImg || !brandsData || !newsData || !mainNewsIds
	if (isDataLoading) return <Loading />;

	const brands = Object.entries(brandsData).map(([key, value]) => value);

	return (
	<div className={styles.home}>
		<Carousel />
		<div className={styles.intro}>
			<div className={styles.moto}>
				<BUL>{introData.moto}</BUL>
			</div>
			<div className={styles.companyName}>
				{introData.companyName}
			</div>
			<div className={styles.descWrap}>
				<div className={styles.desc1}>{introData.desc1}</div>
				<div className={styles.desc2}>{introData.desc2}</div>
				<div className={styles.desc3}>{introData.desc3}</div>
			</div>
		</div>
		<div className={styles.business}>
			<Title title="OUR BUSINESS" to='/business'></Title>
			<div style={{display: 'flex', justifyContent: 'center'}}>
				<WrappedImg 
					imgId={businessImg} 
					className={styles.businessImgWrap}
					alt='
					CONTENTS PRODUCTION, 
					MEDIA DISTRIBUTION,
					LICENSING,
					MERCHANDISING,
					REPRESENTATIVE
					'
				/>
			</div>
		</div>
		<div className={styles.brands}>
			<Title title="BRANDS" to='/brands'></Title>
			<BrandList brands={brands} />
		</div>
		<div className={styles.news}>
			<Title title="NEWS" to='/news'></Title>
			<div className={styles.newsWrap}>
				{
					mainNewsIds.map((mainNewsId) => {
						let mainNews = newsData.find((news) => news.id === mainNewsId);
						return <NewsThumbnail 
							title={mainNews.title}
							to={`/news/${mainNews.id}`} 
							date={mainNews.date} 
							imgId={mainNews.thumbnailImgId} 
							key={mainNews.id} 
						/>
					})
				}
			</div>
		</div>
	</div>);
}

export default Home