import React from 'react';

type Props = {
	id: 'linedin' | 'linkedin_gray' | 'topBtn' | 'downArrow_black' | 'downArrow_white' | 'rightArrow' | 'leftArrow'
	style?: React.CSSProperties | undefined
	className?: string | undefined
}

const Icon = (props: Props) => {
	const getIconPath = (id: string) => {
		const iconList = [
			{ id: 'linkedin', path: '/icons/linkedin.svg' },
			{ id: 'linkedin_gray', path: '/icons/linkedin_gray.svg' },
			{ id: 'topBtn', path: '/icons/topBtn.png' },
			{ id: 'downArrow_black', path: '/icons/downArrow_black.svg'},
			{ id: 'downArrow_white', path: '/icons/downArrow_white.svg'},
			{ id: 'rightArrow', path: '/icons/rightArrow.png'},
			{ id: 'leftArrow', path: '/icons/leftArrow.png'},
		]

		const icon = iconList.find((icon) => icon.id === id)
		if (icon) return icon.path;

		throw new Error('Icon not found.');
	}

	return <img src={getIconPath(props.id)} className={props.className} style={props.style} />
}

export default Icon;