import React from "react";
import styles from './desc.module.css'
import { Link } from "react-router-dom";
import Icon from "../../../components/ui/icon/Icon";
import MyPre from "../../../components/ui/myPre/MyPre";
import WrappedImg from "../../../components/ui/img/WrappedImg";

type Props = {
	blocks: Array<any>
}

const styleToClass = (classNames: string) => {
	if (!classNames) {
		return undefined;
	}

	return classNames
		.split(' ')
		.map((className) => styles[className])
		.join(' ')
}

const Desc = ({blocks}: Props) => {
	if (typeof blocks === 'string') return <></>;
	return <>{
		blocks.map((block, index) => <BlockComponent key={index} {...block} />)
	}</>
}

const BlockComponent = (props: any) => {
	if (props.type === 'text') return <TextBlock {...props}/>;
	if (props.type === 'br') return <br />;
	if (props.type === 'link') return <LinkBlock {...props}/>;
	if (props.type === 'img') return <ImgBlock {...props}/>;
	if (props.type === 'icon') return <IconBlock {...props}/>;

	console.error(`props.type is invalid: ${props.type}`);

	return <></>
}

const TextBlock = (props: any) => {
	let content;
	if (typeof props.content === 'string') {
		content = props.content;
	} else {
		content = <BlockComponent {...props.content} />
	}

	return <p className={styleToClass(props.style)}>
		<MyPre>{content}</MyPre>
	</p>
}

const LinkBlock = (props: any) => {
	let content;
	if (typeof props.content === 'string') {
		content = props.content;
	} else {
		content = <BlockComponent {...props.content} />
	}

	return <div>
		<Link to={props.href} className={styleToClass(props.style)} target={props.target}>
			{content}
		</Link>
	</div>
}

const IconBlock = (props: any) => {
	return <Icon id={props.content} className={styles.icon}/>
}

const ImgBlock = (props: any) => {
	return <WrappedImg className={styles.img} imgId={props.content} />
}

export default Desc;