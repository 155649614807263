import React from "react";
import { Link, useParams } from "react-router-dom";

import BUL from "../../components/ui/bigUpperLetter/bigUpperLetter";
import NotFoundError from "../../components/ui/error/NotFoundError";
import WrappedImg from "../../components/ui/img/WrappedImg";
import Loading from "../../components/ui/loading/Loading";
import MyPre from "../../components/ui/myPre/MyPre";
import NewsThumbnail from "../../components/ui/newsThumbnail/NewsThumbnail";
import { useData } from "../../hooks/useData";

import styles from './brandDetail.module.css';
import { useScreenSize } from "../../contexts/screenSizeContext";

const BrandDetail = () => {
	let { brandId } = useParams();
	const brandsData = useData('brands');
	const newsData = useData('news');
	const {screenSize} = useScreenSize();

	const isLoading = (!brandId || !brandsData || !newsData);
	if (isLoading) return <Loading />;

	const brand = (brandsData as Array<any>).find((brand: any) => brand.id == brandId);

	if (!brand) return <NotFoundError />

	const relatedNews = newsData.filter((news: any) => (brand.news.includes(news.id)))

	return (
	<div className={styles.brands}>
		<div className={styles.banner}>
			<WrappedImg 
				imgId={brand.img.banner}
				className={styles.imgWrap}
			/>
		</div>
		<div className={styles.brandDetail}>
			<div className={styles.pageName}>
				Program details
			</div>
			<div className={styles.brandName}>
				<BUL>{brand.name}</BUL>
			</div>
			<div className={styles.section1}>
				<div className={styles.desc}>
					<MyPre>{brand.detail}</MyPre>
				</div>
				<table className={styles.table}>
					{brand.tableData.map((row: any, index: number) => {
						const rowValue = (
							screenSize == 'Desktop' ?
							row.value :
							row.value.replaceAll('|', '\n')
						)

						return(
						<tr className={styles.row} key={index}>
							<td className={styles.rowName}><BUL>{row.name}</BUL></td>
							<td className={styles.rowValue}><MyPre>{rowValue}</MyPre></td>
						</tr>
					)})}
				</table>
				<div className={styles.sns}>
					{brand.sns.map((sns: any, index:number) => (
						<a href={sns.url} target='_blank' key={index}>
							<img className={styles.snsImg} src={`/icons/${sns.name}_gray.svg`} />
						</a>
					))}
				</div>
			</div>
			<div className={styles.imgs}>
				{
					brand.img.scene.large.map((imgId: string, index: number) => (
						<WrappedImg className={styles.imgWrap + ' ' + styles.large} imgId={imgId} key={index}/>
					))
				}
				{
					brand.img.scene.small.map((imgId: string, index: number) => (
						<WrappedImg className={styles.imgWrap + ' ' + styles.small} imgId={imgId} key={index}/>
					))
				}
			</div>
			<div className={styles.section2}>
				<div className={styles.partner}>
					{
						brand.img.partner
						? <>
							<div className={styles.sectionName}><BUL>Partners</BUL></div>
							<WrappedImg className={styles.imgWrap} imgId={brand.img.partner} />
						</>
						: <></>
					}
				</div>
				<div className={styles.relatedNews}>
					{
						brand.news.length > 0
						? <>
							<div className={styles.sectionName}><BUL>Related News</BUL></div>
							<div className={styles.newsThumbnails}>
								{relatedNews.map((news: any, index: number) => (
										<NewsThumbnail
											imgId={news.thumbnailImgId}
											title={news.title}
											date={news.date}
											to={`/news/${news.id}`}
											styleType='brandRelated'
											key={index}
										/>
									))}
							</div>
						</>
						: <></>
					}
				</div>
			</div>
		</div>
	</div>
	);
}

export default BrandDetail;