import React from "react";

import { Wrapper, Status } from "@googlemaps/react-wrapper";

import styles from "./map.module.css";
import BUL from "../../../components/ui/bigUpperLetter/bigUpperLetter";

const render = (status: Status) => {
	if (status === Status.LOADING) {
		return <h1 className={styles.mapLoading}>Loading Map...</h1>;
	} else if (status === Status.FAILURE) {
		return <h1 className={styles.mapLoading}>Failed to load Map.<br /> Please reload.</h1>;
	} else {
		return <></>;
	}
}

interface MapProps extends google.maps.MapOptions {
	style?: { [key: string]: string };
	children?: React.ReactNode;
  }

const Map: React.FC<MapProps> = ({
	children,
	style,
	...options
  }) => {
	const ref = React.useRef<HTMLDivElement>(null);
	const [map, setMap] = React.useState<google.maps.Map>();

	React.useEffect(() => {
		if (ref.current && !map) {
			setMap(new window.google.maps.Map(ref.current, options));
		}
	})

	return <div ref={ref} className={styles.rawMap}>
		{React.Children.map(children, (child) => {
			if (React.isValidElement(child)) {
				// @ts-ignore
				return React.cloneElement(child, { map });
			}
		})}
	</div>;
};

const Marker: React.FC<google.maps.MarkerOptions> = (options) => {
	const [marker, setMarker] = React.useState<google.maps.Marker>();

	React.useEffect(() => {
		if (!marker) {
		setMarker(new google.maps.Marker());
		}

		return () => {
		if (marker) {
			marker.setMap(null);
		}
		};
	}, [marker]);

	React.useEffect(() => {
		if (marker) {
		marker.setOptions(options);
		}
	}, [marker, options]);

	return null;
};

type MapWrapperProps = {
	apiKey: string,
	officeLocation: {lat: number, lng: number, text: string}
}

const MapWrapper = ({apiKey, officeLocation}: MapWrapperProps) => {
	return <div className={styles.mapWrapper}>
		<div className={styles.mapShadow}>
			<Wrapper apiKey={apiKey} render={render}>
				<Map center={officeLocation} zoom={19}>
					<Marker position={officeLocation}/>
				</Map>
			</Wrapper>
		</div>
		<div className={styles.mapText}>
			<div className={styles.locationTitle}>
				<img src="./icons/marker.svg" />
				<span>
					<BUL>Location</BUL>
				</span>
			</div>
			<div className={styles.locationText}>
				{officeLocation.text}
			</div>
		</div>
	</div>;
}

export default MapWrapper;