import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useData } from "../../../hooks/useData";

import styles from './footer.module.css';
import Loading from "../../ui/loading/Loading";
import { useScreenSize } from "../../../contexts/screenSizeContext";
import Icon from "../../ui/icon/Icon";
import DropDown from "../../ui/dropDown/DropDown";
import BUL from "../../ui/bigUpperLetter/bigUpperLetter";

const Footer = () => {
	const footerData = {
		address: useData('contact', 'location', 'text'),
		representative: useData('footer', 'ceo')
	}

	if (!footerData) return <Loading />

	return <footer className={styles.footer}>
		<div className={styles.section1}>
			<FooterMenu />
			<div className={styles.policies}>
				<div className={styles.policyItem}>
					<Link to='/'>Terms of Use</Link>
				</div>
				<div className={styles.policyItem}>
					<Link to='/'>Cookie Policy</Link>
				</div>
			</div>
		</div>
		<div className={styles.Hr}></div>
		<div className={styles.section2}>
			<div className={styles.sns}>
				<div className={styles.instagram}>
					<Link to='/'>
						<img src='/icons/instagram.svg'/>
					</Link>
				</div>
				<div className={styles.youtube}>
					<Link to='/'>
						<img src='/icons/youtube.svg'/>
					</Link>
				</div>
				<div className={styles.linkedin}>
					<Link to='https://www.linkedin.com/company/studio-acorn/'>
						<img src='/icons/linkedin.svg'/>
					</Link>
				</div>
			</div>
			<div className={styles.companyInfo}>
				<div className={styles.address}>
					{footerData.address}
				</div>
				<div className={styles.representative}>
					{footerData.representative}
				</div>
				<div className={styles.copyright}>
						© Studio ACORN Inc. All rights reserved.
				</div>
			</div>
		</div>
	</footer>
}

const FooterMenu = () => {
	const menuData = useData('menu') as Array<{name: string, path: string, color: string}>;
	const brandsData = useData('brands');
	const {screenSize} = useScreenSize();
	const [isDropdownOpen, setIsDropdownOpen] = useState<Boolean>(false);

	if (!menuData || !brandsData) return <Loading />;

	const toggleDropDown = () => {
		setIsDropdownOpen(!isDropdownOpen);
	}

	const upperFirst = (str: string) => (
		str.split(' ')
		.map((val) => val[0].toUpperCase() + val.slice(1))
		.join(' ')
	);

	const MenuList = (props: {index: number, data: any}) => (
		<div className={styles.menuItem} key={props.index}>
			<Link to={props.data.path}>{upperFirst(props.data.name)}</Link>
		</div>
	)

	return <div className={styles.menuList}>
			{
			menuData.map((data, index) => (
				(data.name === 'Brands') && (screenSize == 'Mobile')
				? (
				<div className={styles.menuItem} key={index}>
					<Link to={data.path}>{upperFirst(data.name)}</Link>

					<span className={isDropdownOpen ? `${styles.dropDownBtn} ${styles.open}` : styles.dropDownBtn} onClick={toggleDropDown}>
						<Icon id={'downArrow_white'}/>
					</span>

					<DropDown className={isDropdownOpen ? `${styles.brandDetailDropdown} ${styles.open}` : styles.brandDetailDropdown} isOpen={isDropdownOpen}>
						{
							brandsData.map((brand: any) => {
								return <Link 
									to={`/brands/${brand.id}`} 
									onClick={() => {setIsDropdownOpen(false)}}
								>
									{brand.name}
								</Link>
							})
						}
					</DropDown>

				</div>)
				: <MenuList index={index} data={data} />
			))
		}
	</div>
}

export default Footer;