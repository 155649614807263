import React from "react";
import { Link } from "react-router-dom";
import styles from "./title.module.css";

type Props = {
	title: string,
	to?: string,
	className?: string
}

const Title = ({title, to, className}: Props) => {
	if (to) {
		return <Link to={to} className={className}>
			<h1 className={styles.title}>{title}</h1>
		</Link>;
	}
	return <h1 className={styles.title + ' ' + className}>{title}</h1>
}

export default Title;