import React from 'react'

const NotFoundError = () => {
	return <div style={{
		width: '100vw',
		font: 'bold 10rem nsw',
		textAlign: 'center',
		padding: '25vh 0',
		color: '#9D9D9D'
	}}>
		404 Not Found
	</div>
}

export default NotFoundError;