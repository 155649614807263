import React from "react"

import { PostBlock, PostBlockProps, PostBlockType } from "./postBlock/PostBlock"
import styles from "./post.module.css"

type PostProps = {
	blocks: Array<PostBlockProps>,
	classNames?: Array<{blockType: PostBlockType, className: string }>
}

type ClassNameArray = {
	[blockType in PostBlockType]: string
}


export const Post = (props: PostProps) => {
	const blockClassNames: ClassNameArray = {
		'Heading': '',
		'Heading2': '',
		'Paragraph': '',
		'Img': '',
		'ImgCaption': '',
		'List': '',
		'Table': '',
		'Hr': ''
	};

	if (!props) return <></>;

	if (props.classNames) {
		props.classNames.forEach(({blockType, className}) => blockClassNames[blockType] = className);
	}
	
	return <div className={styles.post}>{
		props.blocks.map((block, idx) => {
			let className = '';
			if (blockClassNames[block.blockType]) {
				className = blockClassNames[block.blockType] + ' ';
			}
			if (block.className) {
				className += block.className
			}
			return <PostBlock {...block} className={className} key={idx}/>
		})
	}</div>;
}