import React from "react"
import styles from "./topbtn.module.css"
import Icon from "../../ui/icon/Icon";

const TopBtn = () => {
	return <div className={styles.topBtn} onClick={() => {
		window.scrollTo(0, 0);
	}}>
			<Icon id={'topBtn'} />
	</div>
}

export default TopBtn;